import { addBreadcrumb, setUser } from "@sentry/react";
import type { AxiosError } from "axios";
import { atom } from "jotai";

import { enableAdsReporting, initVideoAds } from "@sunrise/ads";
import {
  createAnonymousUserMutationAtom,
  deviceTypeAtom,
  fetchRefreshAuthTokens,
} from "@sunrise/auth";
import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { ngHttpClientConfigAtom } from "@sunrise/backend-ng-core";
import type { Language } from "@sunrise/backend-types-core";
import { languages } from "@sunrise/backend-types-core";
import type { BaseError } from "@sunrise/error";
import { errorAtom } from "@sunrise/error";
import { growthbookConfigAtom } from "@sunrise/feature-flags";
import {
  createPrivateApi,
  hostsAtom,
  httpClientAtom,
  publicApi,
} from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import {
  actionJWTClear,
  actionJWTSetTokens,
  jwtAtom,
  selectAccessToken,
  selectRefreshToken,
} from "@sunrise/jwt";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import {
  initMonitoring as initSunriseMonitoring,
  logMessage,
} from "@sunrise/monitoring";
import {
  getVideoElement,
  initVideoPlayer,
  playerDelayedBufferSettingsAtom,
  playerLiveBufferSettingsAtom,
} from "@sunrise/player";
import {
  actionPlayerStatsSetEnabled,
  actionPlayerStatsToggleEnabled,
  playerStatsAtom,
} from "@sunrise/player-stats";
import { getTranslationFileAtom } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import { deviceInfo } from "@sunrise/utils";
import {
  disableAutoStartAtom,
  initPlayerManager,
} from "@sunrise/yallo-common-player-manager";
import { initFullscreenManager } from "@sunrise/yallo-player-controls";
import {
  appVersionAtom,
  platformAtom,
  settingsVersionAtom,
} from "@sunrise/yallo-settings";
import { isNotKnownUserError, userAtom } from "@sunrise/yallo-user";
import {
  deviceIdAtom,
  getClientID,
  legacySocketUrlAtom,
  socketUrlAtom,
  userAgentAtom,
} from "@sunrise/yallo-websocket";

import { route } from "@/config/route";
import { webStore } from "@/core";
import { getTranslationFile } from "@/modules/i18n/get-translation-file";

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_ENDPOINT;
const NG_WEBSOCKET_URL = import.meta.env.VITE_NG_WEBSOCKET_ENDPOINT;
const appVersion = import.meta.env.VITE_APP_VERSION;

const environment = import.meta.env.MODE;
const isProdMode = environment === "production";

// TODO: Read value from env variable defined on AWS
// We fallback to null when the value is falsey ("") for example.
const dsn = import.meta.env.VITE_SENTRY_DSN || null;

export const initIntegrations = () => {
  const onRetry = (error: AxiosError) => {
    addBreadcrumb({
      category: "http-retry",
      message: "Request was retried",
      level: "info",
      data: {
        name: error.name,
        status: error.response?.status,
      },
    });
  };
  // NOTE: This is a temporary solution to avoid having a refresh from ng backend and a refresh from legacy backend at the same time.
  let refreshPromise: Nullable<ReturnType<typeof fetchRefreshAuthTokens>>;
  const doRefreshTokens = async (refreshToken: string) => {
    const host = webStore.get(hostsAtom).api;
    if (!host) {
      throw new Error("No host found");
    }

    if (!refreshPromise) {
      refreshPromise = fetchRefreshAuthTokens(
        host,
        refreshToken,
        !webStore.get(isLegacyBackendAtom),
      );
    }

    const result = await refreshPromise;
    refreshPromise = null;
    return result;
  };

  webStore.set(httpClientAtom, {
    privateApi: createPrivateApi(webStore, (refreshToken: string) => {
      const host = webStore.get(hostsAtom).api;
      if (!host) {
        throw new Error("No host found");
      }
      return fetchRefreshAuthTokens(
        host,
        refreshToken,
        !webStore.get(isLegacyBackendAtom),
      );
    }),
    publicApi,
  });
  const ngBaseUrl = import.meta.env.VITE_NG_API_ENDPOINT;
  if (ngBaseUrl) {
    webStore.set(ngHttpClientConfigAtom, {
      // The real config.
      baseUrl: ngBaseUrl,
      // Stuff for the error interceptor.
      doRefreshTokens,
      isNotKnownUserError,
      onRetry,
      getAccessToken: () => webStore.get(selectAccessToken),
      getRefreshToken: () => selectRefreshToken(webStore.get(jwtAtom)),
      setTokens: (accessToken, refreshToken, wsToken) => {
        webStore.set(
          jwtAtom,
          actionJWTSetTokens({ accessToken, refreshToken, wsToken }),
        );
      },
      logInAsAnonymousUser: async () => {
        await webStore.set(createAnonymousUserMutationAtom);
      },
      resetTokens: (error: BaseError) => {
        webStore.set(errorAtom, error);
        webStore.set(jwtAtom, actionJWTClear());
      },
      getLanguage: () => webStore.get(currentLanguageAtom),
      // NOTE: We did not yet pass stuff for the manual retry interceptor.
      //       Which is the mechanism that kicks in when we fail to log in.
    });
  }
  webStore.set(platformAtom, "web");
  webStore.set(settingsVersionAtom, "8");
  webStore.set(
    deviceTypeAtom,
    deviceInfo.isIOS || deviceInfo.isSafari
      ? "webclient_macos_safari"
      : "webclient",
  );
  webStore.set(hostsAtom, {
    api: import.meta.env.VITE_API_ENDPOINT,
    clients: import.meta.env.VITE_CLIENTS_ENDPOINT,
    data: import.meta.env.VITE_DATA_ENDPOINT,
    domain: import.meta.env.VITE_PUBLIC_DOMAIN,
  });
  if (WEBSOCKET_URL) {
    webStore.set(legacySocketUrlAtom, WEBSOCKET_URL);
  }
  if (NG_WEBSOCKET_URL) {
    webStore.set(socketUrlAtom, NG_WEBSOCKET_URL);
  }
  const clientId = getClientID("client_id");
  if (clientId) {
    webStore.set(deviceIdAtom, atom(Promise.resolve(clientId)));
  }
  webStore.set(appVersionAtom, import.meta.env.VITE_APP_VERSION);
  webStore.set(userAgentAtom, window.navigator.userAgent);
  webStore.set(getTranslationFileAtom, { fn: getTranslationFile });

  initPlayerManager(webStore, import.meta.env.MODE === "production", {
    hasPauseUpsell: false,
  });

  if (window.disablePlayer) {
    webStore.set(disableAutoStartAtom, true);
  }

  const showPlayer = () =>
    webStore.set(locationAtom, actionLocationNavigate(route.tv.root()));

  initVideoAds(
    webStore,
    getVideoElement,
    showPlayer,
    import.meta.env.MODE !== "production",
  );

  initVideoPlayer(webStore, {
    showPlayer,
    getPlayerBufferSettings: {
      live: () => webStore.get(playerLiveBufferSettingsAtom),
      delayed: () => webStore.get(playerDelayedBufferSettingsAtom),
    },
    // Make sure to inject the player error into the regular error atom so we can catch it before the ErrorBoundary.
    onError: (e) => webStore.set(errorAtom, e),
    logError: (e) => logMessage(e.name, "warning"),
    isEnabled: !window.disablePlayer,
  });

  if (import.meta.env.MODE === "test") {
    webStore.set(currentLanguageAtom, "de" as Language);
  }

  const growthBookApiHost = import.meta.env.VITE_GROWTHBOOK_API_HOST as
    | string
    | undefined;
  const growthBookClientKey = import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY as
    | string
    | undefined;
  if (growthBookApiHost && growthBookClientKey) {
    webStore.set(growthbookConfigAtom, {
      enableDevMode: false,
      apiHost: growthBookApiHost,
      clientKey: growthBookClientKey,
    });
  }

  async function updateDynamicSentryContext(): Promise<void> {
    const user = await webStore.get(userAtom);
    setUser({ id: user.data?.id });
  }

  webStore.sub(userAtom, updateDynamicSentryContext);

  // For testing purposes
  if (import.meta.env.MODE !== "production") {
    if (import.meta.env.VITE_DISABLE_AD_REPORTING === "true") {
      webStore.set(enableAdsReporting, false);
    }

    if (import.meta.env.VITE_PLAYER_STATS_DEFAULT === "true") {
      webStore.set(playerStatsAtom, actionPlayerStatsSetEnabled());
    }

    initFullscreenManager(webStore);

    document.addEventListener("keypress", (event) => {
      if (!event.ctrlKey) return;
      switch (event.key) {
        case "i": {
          webStore.set(playerStatsAtom, actionPlayerStatsToggleEnabled());
          break;
        }
        case "]":
        case "[": {
          const increase = event.key === "]";
          const currentLanguage = webStore.get(currentLanguageAtom);
          const bound = increase
            ? languages[0]
            : languages[languages.length - 1]!;
          const nextLanguage =
            languages[
              languages.indexOf(currentLanguage) + (increase ? 1 : -1)
            ] ?? bound;
          webStore.set(currentLanguageAtom, nextLanguage);
          break;
        }
      }
    });
  }
};

export const logVersion = () => {
  if (!import.meta.env.PROD) {
    return;
  }

  // eslint-disable-next-line no-console
  console.info(`
  mode: ${import.meta.env.MODE}
  version: ${appVersion}
  commit: ${import.meta.env.VITE_APP_COMMIT_HASH}
  build: ${import.meta.env.VITE_APP_BUILD_NR} ${
    import.meta.env.VITE_APP_BUILD_DATE
      ? `(${new Date(
          Number(import.meta.env.VITE_APP_BUILD_DATE),
        ).toLocaleString()})`
      : ""
  }`);
};

export const initMonitoring = () => {
  initSunriseMonitoring(
    {
      dsn,
      environment,
      isProdMode,
    },
    {
      initialScope: (scope) => {
        // scope.setTags(makeMonitoringTags());
        // scope.setExtras(makeMonitoringExtraData());
        return scope;
      },
    },
  );
};
