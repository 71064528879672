import { isAfter, isBefore } from "date-fns";
import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import {
  ngRecordingsApiAtom,
  RecordingScheduleTypes,
} from "@sunrise/backend-ng-recordings";
import type { EPGEntryId, TimeISOString } from "@sunrise/backend-types-core";
import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { errorAtom } from "@sunrise/error";
import {
  selectCanRecord,
  selectCanRecordInPast,
  selectCanRecordSeries,
} from "@sunrise/jwt";
import { nowAtom } from "@sunrise/time";
import {
  flushRecordingsState,
  recordingStatusByEpgIdAtom,
} from "@sunrise/yallo-recordings";
import type { UpsellErrorCode } from "@sunrise/yallo-upsell";
import { UpsellError } from "@sunrise/yallo-upsell";

import { deleteRecordingAtom } from "./delete-recording.atom";
import type { RecordingActionButtonResult } from "./types";

const triggerUpsellAtom = (code: UpsellErrorCode) =>
  atom(undefined, async (_, set) => {
    set(errorAtom, new UpsellError(code));
  });

/**
 * This atom should trigger all the upsell logic and be re-usable for Tizen.
 *
 * When a program is an epg program
 *   And it is recorded
 *   - we will show the action to delete the recording
 *   And it is not recorded
 *   - we will show the action to record the program
 *
 * When the user is not allowed to record (not allowed to record past items)
 * - we will not show the button for now
 *
 * When the user has hit the limit of recordings
 * - we will not show a modal on the client for now.
 *
 * The idea is to not load the necessary logic inside the family but pass it to it.
 * This is because this atom will be used in lists and we do not want to run unnecessary detail calls for static data for every item.
 */
export const manageEpgSingleProgramRecordingsActionsAtom = atomFamily(
  ({
    isSeriesEpisode,
    startTime,
    endTime,
    epgId,
  }: {
    epgId: EPGEntryId;
    /**
     * This is to assist the atom so we do not have to load the epg details to determine if it is in the past or not.
     */
    startTime: TimeISOString;
    /**
     * This is to assist the atom so we do not have to load the epg details to determine if it is in the past or not.
     */
    endTime: TimeISOString;
    isSeriesEpisode: boolean;
  }) => {
    // This means we do not have a recording yet and we should be able to plan something.
    // When we have a premium user, we can always show this.
    // When we have a non-premium user, we can show this when the program is in the future.
    const mutation = (isSeries: boolean) => {
      return atomWithMutation((get) => {
        const api = get(ngRecordingsApiAtom);

        return {
          mutationKey: [
            "create-recording-schedule-ng",
            isSeries ? "series" : "single",
            epgId,
          ],
          mutationFn: async () => {
            await api.recording.createNewRecordingScheduleRecordingV1RecordingSchedulesPost(
              {
                epg_entry_id: epgId,
                type: isSeries
                  ? RecordingScheduleTypes.Asset
                  : RecordingScheduleTypes.Single,
              },
            );
          },
          onSettled: () => {
            // TODO: Need to build some form of helper to flush the recordings state on a mutation ... .
            // TODO: When we modified the logic to inject a temporary status, we can remove the instant flush and just make it delayed.
            flushRecordingsState(get(queryClientAtom), get, true);
          },
        };
      });
    };

    const lastMutationAtom = atom<ReturnType<typeof mutation> | null>(null);

    const inner = atom<Promise<RecordingActionButtonResult>>(async (get) => {
      const status = await get(recordingStatusByEpgIdAtom(epgId));

      if (!status) {
        const canRecordPast = get(selectCanRecordInPast);
        const canRecordFuture = get(selectCanRecord);

        const now = get(nowAtom);

        const startsInPast = isBefore(startTime, now);
        const endsInFuture = isAfter(endTime, now);
        const startsInFuture = isAfter(startTime, now);
        const isLive = startsInPast && endsInFuture;

        const canRecord =
          ((startsInFuture && canRecordFuture) ||
            ((startsInPast || isLive) && canRecordPast)) ??
          false;

        // TODO: Implement locked channels since these can not be recorded.
        // const isChannelLocked = get(isChannelLockedAtom(channel.id));

        // When we can not record, we will not show the button for now until we implement upsell.
        if (!canRecord) {
          return {
            actionAtom: triggerUpsellAtom("UPSELL_SINGLE_RECORDING"),
            action: "record" as const,
            isLoading: false,
          };
        }

        const lastMutation = get(lastMutationAtom);

        // TODO: Implement recording limit on client-side? Or will the ng-backend tackle this?
        // const atCapacity = await get(isAtMaxRecordingCapacityAtom); // error_recording_capacity_reached

        return {
          actionAtom: atom(undefined, async (get, set) => {
            const confirmRecording = ({ isSeries }: { isSeries: boolean }) => {
              const canCreateSeriesRecording = get(selectCanRecordSeries);
              if (isSeries && !canCreateSeriesRecording) {
                set(triggerUpsellAtom("UPSELL_SERIES_RECORDING"));
                return;
              }

              const selected = mutation(isSeries);
              set(lastMutationAtom, selected);
              get(selected).mutateAsync();
            };

            // TODO: When the channel is locked, we need to show a modal for upsell.

            // TODO: Before we record, check if we hit the limit of recordings.
            // const atCapacity = await get(isAtMaxRecordingCapacityAtom);

            // TODO: Switch from not giving the option to showing the option but float an upsell when the user clicks on series recording and they are not allowed to.
            //       Or adjust the modal mechanism to add disabled options if they insist on showing the button already.
            if (isSeriesEpisode) {
              set(
                dialogAtom,
                actionDialogOpen({
                  title: { key: "dialog_start_recording_title" },
                  description: { key: "dialog_start_recording_subtitle" },
                  // TODO: Swap this out with something else more suited & shareable w/ tizen as well as web.
                  lastFocusKey: "",
                  type: "actions",
                  id: "confirm-series-or-single-recording",
                  actions: [
                    {
                      label: { key: "dialog_start_recording_series" },
                      key: "series-recording",
                      action: () => {
                        confirmRecording({ isSeries: true });
                      },
                    },
                    {
                      label: { key: "dialog_start_recording_single" },
                      action: () => {
                        confirmRecording({ isSeries: false });
                      },
                      key: "single-episode",
                    },
                    {
                      label: { key: "dialog_button_cancel" },
                      key: "cancel",
                      action: () => {
                        // TODO: Extend actions atom to allow action to be something other than just a function.
                      },
                      // TODO: to check which one should be primary
                      primary: true,
                      initialFocus: true,
                    },
                  ],
                }),
              );
              return;
            }

            confirmRecording({ isSeries: false });
          }),
          isLoading: lastMutation ? get(lastMutation).isPending : false,
          action: "record",
        };
      }

      // delete or cancel a recording
      return deleteRecordingAtom(status.recordingId, status.status, get);
    });

    inner.debugLabel = `manageEpgSingleProgramRecordingsActionsAtom(${JSON.stringify(
      { epgId, startTime, endTime, isSeriesEpisode },
    )})`;

    return inner;
  },
  areEqual,
);
