import { useAtomValue } from "jotai/react";
import { useMediaQuery } from "usehooks-ts";

import { useTranslator } from "@sunrise/translator";
import { upsellLinkQueryAtom } from "@sunrise/yallo-upsell";
import { Link, Text } from "@sunrise/yallo-web-components";

import styles from "./ads.module.css";

function UpsellButton() {
  const smallScreen = useMediaQuery("(width < 800px");
  const upsell = useAtomValue(upsellLinkQueryAtom("ads"));
  const t = useTranslator();

  if (smallScreen || !upsell.data) return;

  return (
    <Link
      className={styles.button}
      href={upsell.data.upsell_link}
      variant="none"
    >
      <Text size="small" variant="label">
        {t("ad_screen_no_more_ads_button")}
      </Text>
    </Link>
  );
}

export { UpsellButton };
