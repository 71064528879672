import type { ComponentProps, ReactNode } from "react";
import { useAtomValue } from "jotai";
import { Route } from "wouter";

import { selectCanLogin } from "@sunrise/jwt";
import { PageSpinner } from "@sunrise/yallo-web-components";

import { login } from "@/core/auth";

export function ProtectedRoute(props: ComponentProps<typeof Route>): ReactNode {
  const canLogIn = useAtomValue(selectCanLogin);
  if (canLogIn) {
    // Redirect to login if not authenticated
    login(window.location.href);
    return <PageSpinner />;
  }
  return <Route {...props} />;
}
