import type { ReactNode } from "react";

import type { Translatable } from "@sunrise/i18n";
import { useTranslatable } from "@sunrise/translator";

import styles from "./error-dialog-description.module.css";

type ErrorDialogDescriptionProps = {
  eventId: string | undefined;
  description: Translatable;
};

export function ErrorDialogDescription({
  eventId,
  description,
}: ErrorDialogDescriptionProps): ReactNode {
  const t = useTranslatable();
  if (!eventId) {
    return t(description);
  }

  return (
    <span className={styles.description}>
      <span>{t(description)}</span>
      <span className={styles.event}>Event ID: {eventId}</span>
    </span>
  );
}
