import { type ReactElement, useState } from "react";
import { useAtomValue } from "jotai";

import {
  MemoryRouter,
  MemoryRouterProvider,
  useCreateMemoryRouter,
  useMemoryRouter,
} from "@sunrise/routing";
import { useTranslatable, useTranslator } from "@sunrise/translator";
import { feedbackEnabledAtom } from "@sunrise/yallo-settings";
import { Button, Link, SlideOut, Text } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";
import { login } from "@/core";
import { useLogout } from "@/hooks";

import { LanguageSwitcher } from "../language-switcher";
import styles from "./settings.module.css";
import { SettingsProfile } from "./settings-profile";
import { SettingsTermsOfUse } from "./settings-terms-of-use";

const Settings = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const t = useTranslatable();

  const router = useCreateMemoryRouter(
    [
      {
        id: "root",
        title: { key: "menu_settings" },
        component: <BaseSettings setOpen={setOpen} />,
      },
      {
        id: "language-switcher",
        title: { key: "settings_language" },
        component: <LanguageSwitcher />,
      },
      {
        id: "profile",
        title: { key: "settings_page_profile_title" },
        component: <SettingsProfile />,
      },
      {
        id: "terms-of-use",
        title: { key: "settings_terms_of_use" },
        component: <SettingsTermsOfUse />,
      },
    ],
    "root",
  );

  const isRoot = router.currentRoute?.id === "root";

  return (
    <SlideOut.Root
      open={open}
      title={t(router.currentRoute?.title)}
      titlePrefix={
        !isRoot && (
          <Button
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() => router.navigate("root")}
          >
            {t("button_go_back")}
          </Button>
        )
      }
      trigger={
        <Button icon="settings" variant="text" hideLabel>
          {t("menu_settings")}
        </Button>
      }
      onOpenChange={(nextOpen) => {
        // NOTE: reset to root when opening (not closing, because then it would be visible during the close animation)
        if (!open && nextOpen && !isRoot) {
          router.navigate("root");
        }
        setOpen(nextOpen);
      }}
    >
      <MemoryRouterProvider router={router}>
        <MemoryRouter />
      </MemoryRouterProvider>
    </SlideOut.Root>
  );
};

const BaseSettings = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}): ReactElement => {
  const t = useTranslator();

  const feedbackEnabled = useAtomValue(feedbackEnabledAtom);
  const { navigate } = useMemoryRouter();
  const { canUserLogout, logout } = useLogout();

  const handleLogin = () => login();

  return (
    <>
      <SlideOut.ScrollableList className={styles.baseList}>
        <SlideOut.Button
          icon="language"
          showMore
          onClick={() => navigate("language-switcher")}
        >
          {t("settings_language")}
        </SlideOut.Button>
        <SlideOut.Button
          icon="login"
          showMore
          onClick={() => navigate("profile")}
        >
          {t("settings_page_profile_title")}
        </SlideOut.Button>
        <SlideOut.Button
          icon="termsOfUse"
          showMore
          onClick={() => navigate("terms-of-use")}
        >
          {t("settings_terms_of_use")}
        </SlideOut.Button>
        <SlideOut.Link
          href={t("settings_help_support_url")}
          icon="support"
          target="_blank"
        >
          {t("menu_support")}
        </SlideOut.Link>
        {feedbackEnabled && (
          <SlideOut.Link
            href={t("settings_feedback_url")}
            icon="feedback"
            target="_blank"
          >
            {t("settings_feedback")}
          </SlideOut.Link>
        )}
        {import.meta.env.MODE !== "production" && (
          <>
            <SlideOut.Link
              href={route.devTools.root()}
              icon="login"
              onClick={() => setOpen(false)}
            >
              Dev: Login
            </SlideOut.Link>
            <SlideOut.Link
              href={route.devTools.featureFlags()}
              icon="info"
              onClick={() => setOpen(false)}
            >
              Dev: Feature Flags
            </SlideOut.Link>
          </>
        )}
        <div className={styles.companyInformation}>
          <Link
            href={t("company_information_url")}
            target="_blank"
            variant="none"
          >
            <Text size="large" variant="label">
              {t("company_information")}
            </Text>
          </Link>
        </div>
      </SlideOut.ScrollableList>
      <SlideOut.Actions>
        {canUserLogout ? (
          <Button
            className={styles.authButton}
            variant="outlined"
            onClick={async () => {
              await logout();
              setOpen(false);
            }}
          >
            {t("button_logout")}
          </Button>
        ) : (
          <Button className={styles.authButton} onClick={handleLogin}>
            {t("login_button")}
          </Button>
        )}
      </SlideOut.Actions>
    </>
  );
};

export { Settings };
