import { useSetAtom } from "jotai/react";

import { searchIsOpenAtom } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

export function SearchButton() {
  const t = useTranslator();
  const setSearchOpened = useSetAtom(searchIsOpenAtom);

  return (
    <>
      <Button
        icon="search"
        variant="text"
        hideLabel
        onClick={() => setSearchOpened(true)}
      >
        {t("menu_search")}
      </Button>
    </>
  );
}
