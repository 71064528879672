import { type ReactNode, useId } from "react";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

import { Icon, type Icons } from "@sunrise/icons";
import type { Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

import { AirTime } from "../air-time";
import { Button, Link } from "../button";
import buttonStyles from "../button/button.module.css";
import { ChannelLogo } from "../logo";
import { Text, Title } from "../text";
import styles from "./program-box.module.css";
import { ProgramCover } from "./program-cover";
import { RecordingDeleteWarning } from "./recording-delete-warning";

export type ProgramBoxProps = {
  variant: MappedDisplayType;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  accessibleSubtitle?: Nullable<string>;
  title: Nullable<string>;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  formattedStart: Nullable<string>; // formatted string 13:00
  formattedEnd: Nullable<string>; // formatted string 15:00
  airTime: Nullable<string>;
  subtitle?: Nullable<string>;
  active?: boolean;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  recordingState?: Nullable<SimplifiedRecordingStatus>;
  expiresIn?: Nullable<number>;
  topProgramNumber?: number;
  recTagLabel?: string;
  isLive?: boolean;
  onPlay?: (() => void) | null;
  isDirectory?: boolean;
  redirectUrl?: Nullable<string>;
  playLabel?: Nullable<string>;
  playIcon: Icons | null;
} & Pick<CommonProps, "className">;

const PLAY_ICON_DEFAULT: Icons = "play";

function ProgramBox({
  title,
  startDate,
  endDate,
  expiresIn,
  className,
  formattedEnd,
  formattedStart,
  accessibleSubtitle,
  subtitle,
  channelLogo,
  channelName,
  coverImageUrl,
  variant,
  airTime,
  active,
  isDirectory,
  isLive,
  liveProgress,
  replayProgress,
  recordingState,
  topProgramNumber,
  recTagLabel,
  onPlay,
  redirectUrl,
  playLabel,
  playIcon,
}: ProgramBoxProps): ReactNode {
  const id = useId();
  const isListViewRecording =
    useMediaQuery("(width < 1024px)") && variant === "rowbox";
  const isHorizontal = variant === "horizontal";
  const size = isListViewRecording || isHorizontal ? "small" : "medium";
  const isRecordingGroup = isListViewRecording && !isDirectory;
  const playButtonClassName = clsx(
    !isListViewRecording && [
      buttonStyles.button,
      buttonStyles.outlined,
      buttonStyles.iconOnly,
    ],
    styles.playButton,
  );

  return (
    <article
      aria-labelledby={`${id}-title`}
      className={clsx(
        styles.wrapper,
        {
          [styles.interactive]: onPlay || redirectUrl,
          [styles.active]: active,
        },
        className,
      )}
    >
      {/* The link is at the top so that it's the first element in the tab order, before the play button */}
      {redirectUrl && (
        <Link
          className={styles.overlayAction}
          href={redirectUrl}
          variant="none"
        >
          <VisuallyHidden>{title}</VisuallyHidden>
        </Link>
      )}
      <div className={clsx(variant && styles[variant], styles.container)}>
        <div className={styles.epgInformation}>
          {isHorizontal && (
            <ChannelLogo
              className={styles.channelLogo}
              logo={channelLogo}
              name={channelName}
            />
          )}
          <AirTime
            className={styles.airTime}
            endDate={endDate}
            formattedEnd={formattedEnd}
            formattedStart={formattedStart}
            isLive={isLive}
            relativeDate={airTime}
            size={size}
            startDate={startDate}
          />
        </div>
        {!isHorizontal && isListViewRecording && (
          <RecordingDeleteWarning
            className={styles.expiresIn}
            expiresIn={expiresIn}
          />
        )}
        <div className={styles.imageContainer}>
          <ProgramCover
            channelLogo={channelLogo}
            channelName={channelName}
            className={styles.cover}
            coverImageUrl={coverImageUrl}
            expiresIn={expiresIn}
            isDirectory={isDirectory}
            liveProgress={liveProgress}
            recTagLabel={recTagLabel}
            recordingState={recordingState}
            replayProgress={replayProgress}
            title={title}
            topProgramNumber={topProgramNumber}
            variant={variant}
            onPlay={isListViewRecording ? onPlay : undefined}
          />
          {!isListViewRecording && !!onPlay ? (
            redirectUrl ? (
              <Button
                className={playButtonClassName}
                icon={playIcon ?? PLAY_ICON_DEFAULT}
                variant="none"
                hideLabel
                onClick={onPlay}
              >
                {`${playLabel} ${title}`}
              </Button>
            ) : (
              <div className={playButtonClassName}>
                <Icon name={playIcon ?? PLAY_ICON_DEFAULT} />
              </div>
            )
          ) : null}
        </div>
        <header className={styles.titles}>
          <Title
            className={clsx(["u-clamp", styles.title])}
            id={`${id}-title`}
            level="h3"
            size={size}
            variant="title"
          >
            {title}
          </Title>
          {!!subtitle && !isRecordingGroup && (
            <Text
              aria-label={accessibleSubtitle ?? undefined}
              as="p"
              className={clsx(["u-clamp", styles.subtitle])}
              size={isListViewRecording || isHorizontal ? "small" : "large"}
              variant={isListViewRecording ? "body" : "label"}
            >
              {subtitle}
            </Text>
          )}
        </header>
      </div>
      {!redirectUrl && !!onPlay && (
        <Button
          className={styles.overlayAction}
          variant="none"
          onClick={onPlay}
        >
          <VisuallyHidden>{`${playLabel} ${title}`}</VisuallyHidden>
        </Button>
      )}
    </article>
  );
}

export { ProgramBox };
