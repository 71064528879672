import type { CSSProperties, ReactNode } from "react";
import { Suspense, useCallback, useId, useRef } from "react";
import type { VirtualItem } from "@tanstack/react-virtual";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";

import { useTranslator } from "@sunrise/translator";
import type {
  MappedDisplayType,
  RecommendationsAtomType,
} from "@sunrise/yallo-recommendations";
import { Button, Title } from "@sunrise/yallo-web-components";

import { PlayableItemBox } from "@/components/playable-item-box";

import styles from "./scrollable-row.module.css";

type ScrollableRowProps = {
  displayType: MappedDisplayType;
  numbered: boolean;
  title: string;
  itemsAtom: RecommendationsAtomType;
  "data-testid"?: string;
};

const GUTTER_SIZE = 32;

const mappedHeights: Partial<Record<MappedDisplayType, number>> = {
  portrait: 598,
  box: 365,
};

export const ScrollableRow = ({
  displayType,
  numbered,
  title,
  "data-testid": testId,
  itemsAtom,
}: ScrollableRowProps): ReactNode => {
  const t = useTranslator();
  const titleId = useId();

  const items = useAtomValue(itemsAtom);

  const parentRef = useRef<HTMLDivElement>(null);
  const arrowLeft = useRef<HTMLButtonElement>(null);
  const arrowRight = useRef<HTMLButtonElement | null>(null);
  const parentWidth = useRef(0);
  const listWidth = useRef(0);
  const listScrollAmount = useRef(0);
  const height = mappedHeights[displayType] ?? 220;

  const virtualizer = useVirtualizer({
    count: items?.length ?? 0,
    estimateSize: () => 256,
    getScrollElement: () => parentRef.current,
    horizontal: true,
    gap: GUTTER_SIZE,
    paddingEnd: GUTTER_SIZE,
    paddingStart: GUTTER_SIZE,
  });

  const renderChildren = useCallback(
    (virtualItem: VirtualItem) => {
      if (!items) return null;
      const item = items[virtualItem.index];

      const itemStyles: CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        transform: `translateX(${virtualItem.start}px)`,
        width: `${virtualItem.size}px`,
      };

      return (
        <Suspense key={virtualItem.key}>
          {item.type === "channel" ? (
            <div style={itemStyles}>
              <PlayableItemBox source="channel" {...item} />
            </div>
          ) : item.type === "epg_entry" ? (
            <div style={itemStyles}>
              <PlayableItemBox
                {...item}
                source="epg"
                topProgramNumber={numbered ? virtualItem.index + 1 : undefined}
                variant={displayType}
              />
            </div>
          ) : item.type === "recording" ? (
            <div style={itemStyles}>
              <PlayableItemBox
                {...item}
                // remove this once we have a mapping layer for the recording stuff too
                epg_end={item.epgEnd}
                epg_start={item.epgStart}
                expiresAt={null}
                id={item.recordingId}
                paddingEndMinutes={item.paddingEndMinutes ?? null}
                paddingStartMinutes={item.paddingStartMinutes ?? null}
                posterUrl={item.coverImageUrl ?? null}
                source="recordingSingle"
                subtitle={item.subtitle ?? null}
                title={item.title ?? null}
              />
            </div>
          ) : null}
        </Suspense>
      );
    },
    [items, displayType, numbered],
  );

  const toggleArrowVisibility = () => {
    if (!arrowLeft.current || !arrowRight.current) return;

    parentWidth.current = parentRef.current?.scrollWidth ?? 0;
    listWidth.current = parentRef.current?.clientWidth ?? 0;

    const isRightDisabled =
      listWidth.current + listScrollAmount.current + GUTTER_SIZE >
      parentWidth.current;
    const isLeftDisabled = listScrollAmount.current <= 0;
    arrowLeft.current.toggleAttribute("disabled", isLeftDisabled);
    arrowRight.current.toggleAttribute("disabled", isRightDisabled);
  };

  const handleOnScroll = useCallback(() => {
    setTimeout(() => {
      listScrollAmount.current = virtualizer.scrollOffset ?? 0;
      toggleArrowVisibility();
    }, 0);
  }, [virtualizer.scrollOffset]);

  if (!items || items?.length === 0) return null;

  return (
    <section aria-labelledby={titleId} data-testid={testId}>
      <header className={styles.rowHeader}>
        <Title
          className={styles.title}
          id={titleId}
          level="h2"
          size="small"
          variant="headline"
        >
          {title}
        </Title>
        <div className={styles.arrows}>
          <Button
            ref={arrowLeft}
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() => {
              const startIdx = virtualizer.range?.startIndex ?? 0;
              const endIdx = virtualizer.range?.endIndex ?? 0;

              virtualizer.scrollToIndex(
                Math.max(startIdx - (endIdx - startIdx), 0),
                {
                  behavior: "smooth",
                  align: "end",
                },
              );
            }}
          >
            {t("home_row_previous_button")}
          </Button>

          <Button
            ref={(ref) => {
              arrowRight.current = ref;
              toggleArrowVisibility();
            }}
            icon="arrowRight"
            variant="text"
            hideLabel
            onClick={() => {
              virtualizer.scrollToIndex(virtualizer.range?.endIndex ?? 0, {
                behavior: "smooth",
                align: "start",
              });
            }}
          >
            {t("home_row_next_button")}
          </Button>
        </div>
      </header>
      <div
        ref={parentRef}
        className="u-horizontal-scroll"
        style={{ height }}
        onScroll={handleOnScroll}
      >
        <div
          style={{
            width: `${virtualizer.getTotalSize()}px`,
            position: "relative",
          }}
        >
          {virtualizer.getVirtualItems().map((item) => renderChildren(item))}
        </div>
      </div>
    </section>
  );
};
