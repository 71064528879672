import { useCallback } from "react";
import { minutesToSeconds } from "date-fns";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type { RecordingId } from "@sunrise/backend-types-core";
import { useTranslator } from "@sunrise/translator";
import { isDefined } from "@sunrise/utils";
import {
  actionPlayerManagerPlayRecording,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import { continueWatchingStatusByRecordingIdAtom } from "@sunrise/yallo-continue-watching";
import {
  recordingByRecordingIdAtom,
  recordingStatusByRecordingIdAtom,
} from "@sunrise/yallo-recordings";

type UsePlayRecordingArgs = {
  recordingId: RecordingId;
};

/**
 * A hook to directly play out a recording. It will resume when resuming is available.
 */
export const usePlayRecording = ({
  recordingId,
}: UsePlayRecordingArgs): {
  play: () => void;
  canPlay: boolean;
  label: string;
  icon: "play" | "resume";
} => {
  const cwStatus = useAtomValue(
    continueWatchingStatusByRecordingIdAtom(recordingId),
  );
  const shouldStartFromBeginning = cwStatus ? cwStatus.isFullyWatched : true;

  const position =
    cwStatus?.type === "recording" ? cwStatus.playPositionSecond : null;

  const recStatus = useAtomValue(recordingStatusByRecordingIdAtom(recordingId));
  const canPlay = recStatus === "recorded";
  const play = useAtomCallback(
    useCallback(
      async (get, set) => {
        if (!canPlay) {
          return;
        }

        const recording = await get(recordingByRecordingIdAtom(recordingId));
        if (!recording || recording.type === "group") {
          return;
        }

        const standardResumePoint = recording.paddingStartMinutes
          ? minutesToSeconds(recording.paddingStartMinutes)
          : undefined;
        set(
          playerManagerAtom,
          actionPlayerManagerPlayRecording(
            recording.id,
            recording.channelId ?? null,
            shouldStartFromBeginning
              ? standardResumePoint
              : // Prioritize position over padding.
                (position ?? standardResumePoint),
          ),
        );
      },
      [recordingId, position, canPlay, shouldStartFromBeginning],
    ),
  );

  const willResume = isDefined(position) || cwStatus?.isFullyWatched === true;

  const t = useTranslator();
  const label = t(
    willResume
      ? "program_box_resume_icon_content_description"
      : "program_detail_page_play_recording",
  );

  return {
    play,
    canPlay,
    label,
    icon: willResume ? "resume" : "play",
  };
};
