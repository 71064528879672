import { clsx } from "clsx";
import { useAtomValue } from "jotai/react";

import { getPrefixAndSubtitle } from "@sunrise/details";
import type { Icons } from "@sunrise/icons";
import type {
  NgSearchEpgResult,
  NgSearchRecordingResult,
} from "@sunrise/search";
import { nowAtom } from "@sunrise/time";
import { useTranslatable } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import { isDefined } from "@sunrise/utils";
import { programIsPlayingAtTime, useEpgRelativeTime } from "@sunrise/yallo-epg";
import {
  AirTime,
  Link,
  ProgramCover,
  Text,
  Title,
} from "@sunrise/yallo-web-components";

import styles from "./search-result.module.css";

type SearchEpisodeOrProgramResultProps = {
  item: NgSearchRecordingResult | NgSearchEpgResult;
  detailRoute?: string;
  play?: Nullable<() => void>;
  progress?: {
    replayProgress: Nullable<number>;
    liveProgress: Nullable<number>;
  };
  playIcon?: Nullable<Extract<Icons, "liveBroadcast" | "resume" | "play">>;
};

export function SearchEpisodeOrProgramResult({
  item,
  detailRoute,
  play,
  playIcon,
  progress,
}: SearchEpisodeOrProgramResultProps) {
  const t = useTranslatable();

  const now = useAtomValue(nowAtom);

  const subtitle = getPrefixAndSubtitle(
    item.seasonNumber,
    item.episodeNumber,
    item.subtitle,
  );

  const startDate = new Date(item.epgStart);
  const endDate = new Date(item.epgEnd);

  const { airTime, formattedEnd, formattedStart } = useEpgRelativeTime({
    startDate,
    endDate,
    expiresAt: null,
  });

  const isLive =
    item.epgStart && item.epgEnd
      ? programIsPlayingAtTime(
          {
            startTime: startDate,
            endTime: endDate,
          },
          now,
        )
      : false;

  const Wrapper = isDefined(detailRoute) ? Link : "div";

  return (
    <div className={styles.container}>
      <Wrapper
        className={clsx(styles.container, styles.epgInformation)}
        href={detailRoute ?? ""}
        variant="none"
      >
        <div className={styles.meta}>
          <header className={styles.header}>
            <Title
              className={styles.title}
              level="h3"
              size="small"
              variant="title"
            >
              {item.title}
            </Title>
            <Text
              aria-label={t(subtitle.accessibleLabel) ?? undefined}
              className={styles.subtitle}
              size="small"
              variant="body"
            >
              {subtitle.label}
            </Text>
          </header>
          <AirTime
            className={styles.airTime}
            endDate={item.epgEnd ? new Date(item.epgEnd) : undefined}
            formattedEnd={formattedEnd}
            formattedStart={formattedStart}
            isLive={isLive}
            relativeDate={airTime}
            size="small"
            startDate={item.epgStart ? new Date(item.epgStart) : undefined}
            variant="body"
          />
        </div>
      </Wrapper>
      <ProgramCover
        channelLogo={item.channelLogo}
        channelName={item.channelName}
        className={clsx(styles.logo, { [styles.playable]: !!play })}
        coverImageUrl={item.coverImageUrl}
        liveProgress={progress?.liveProgress}
        playIcon={playIcon}
        recordingState={
          "recordingStatus" in item ? item.recordingStatus : undefined
        }
        replayProgress={progress?.replayProgress}
        title={item.title}
        variant="search"
        onPlay={
          play
            ? () => {
                play?.();
              }
            : undefined
        }
      />
    </div>
  );
}
