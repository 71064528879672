import { useMemo } from "react";
import { useAtomValue } from "jotai";

import type {
  ChannelId,
  EPGEntryId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { type Icons } from "@sunrise/icons";
import { nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import {
  usePlayChannel,
  usePlayProgram,
} from "@sunrise/yallo-common-player-manager";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";

type usePlayProps = {
  epgId: EPGEntryId;
  channelId: ChannelId;
  epgStart: TimeISOString;
  epgEnd: TimeISOString;
  /**
   * When the label is hidden the live icon `(((•)))` is quite ambiguous.
   * By setting this to true we return a regular play `▶` icon instead.
   * @default true
   */
  useLiveIcon?: boolean;
};

const EMPTY = {
  play: null,
  icon: null,
  label: null,
};

/**
 * @returns The appropriate play action, label and icon
 * 1. Resume playback (if a bookmark exists)
 * 2. Play broadcast live (if currently live)
 * 3. Play from beginning (if a recording exists or it will use replay)
 *
 * If none of the conditions are met if will return an empty state
 */

export function usePlay({
  epgId,
  epgStart,
  epgEnd,
  channelId,
  useLiveIcon = false,
}: usePlayProps):
  | {
      play: () => void;
      icon: Extract<Icons, "liveBroadcast" | "resume" | "play">;
      label: string;
    }
  | typeof EMPTY {
  const now = useAtomValue(nowAtom);
  const t = useTranslator();
  const { play: playLive } = usePlayChannel({ channelId });
  const startTime = useMemo(() => new Date(epgStart), [epgStart]);
  const endTime = useMemo(() => new Date(epgEnd), [epgEnd]);
  const isLive = programIsPlayingAtTime(
    {
      startTime,
      endTime,
    },
    now,
  );

  const {
    canPlay,
    canResume,
    play: playReplayOrRecording,
    resume,
    isFullyWatched,
  } = usePlayProgram({ epgId, channelId, epgStart, epgEnd });

  if (canResume) {
    return {
      icon: "resume",
      play: resume,
      label: t("program_detail_page_button_continue"),
    };
  }

  if (isLive) {
    return {
      icon: useLiveIcon ? "liveBroadcast" : "play",
      play: playLive,
      label: t("program_detail_page_button_live"),
    };
  }

  if (canPlay) {
    return {
      icon: isFullyWatched ? "resume" : "play",
      play: playReplayOrRecording,
      label: t("program_detail_page_button_watch"),
    };
  }

  return EMPTY;
}
