import { useAtomValue } from "jotai";

import { skipAd, videoAdsUIAtom } from "@sunrise/ads";
import { useTranslator } from "@sunrise/translator";
import { Button, Text } from "@sunrise/yallo-web-components";

import styles from "./ads.module.css";

export function SkipButton() {
  const t = useTranslator();
  const { skip } = useAtomValue(videoAdsUIAtom) ?? {};
  const skipCountDown = skip?.countdownInSeconds ?? 0;

  if (!skip?.available) {
    return null;
  }

  return (
    <Button
      aria-disabled={!skip.visible}
      className={styles.button}
      variant="none"
      onClick={skip.visible ? skipAd : undefined}
    >
      <Text size="small" variant="label">
        {skip.visible ? t("ads_skip") : t("ads_skip_in", [skipCountDown])}
      </Text>
    </Button>
  );
}
