import { type ReactElement } from "react";
import clsx from "clsx";

import { Tag } from "../tag";
import styles from "./tag.module.css";

type RecTagProps = { active?: boolean } & Pick<CommonProps, "className">;

function LiveTag({ active, className }: RecTagProps): ReactElement {
  return (
    <Tag
      className={clsx(styles.live, { [styles.active]: active }, className)}
      icon="liveBroadcast"
    >
      Live
    </Tag>
  );
}

export { LiveTag };
