import type { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { Amplify } from "aws-amplify";
import { signInWithRedirect, signOut } from "aws-amplify/auth";
import type { AxiosInstance } from "axios";

import type { KaminoSettings } from "@sunrise/backend-types";
import { endpoints, OAuthGrant, OAuthScope } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import type { Store } from "@sunrise/store";
import { nowAtom } from "@sunrise/time";

import { setRedirectUrl } from "./redirect-url";

// cognito is an AWS service that we use to authenticate in the app
export class CognitoService {
  private domain = "";
  private initializedUserPool?: CognitoUserPool;

  constructor(
    private readonly store: Store,
    private readonly clientName: string,
  ) {}

  public async loginWithCredentials(
    username: string,
    password: string,
  ): Promise<CognitoUserSession> {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userPool = await this.configureAuth(
      [OAuthScope.OPENID, OAuthScope.EMAIL, OAuthScope.PROFILE],
      OAuthGrant.TOKEN,
    );
    await signOut();
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    return new Promise<CognitoUserSession>((res, rej) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: res,
        onFailure: rej,
      });
    });
  }

  private async configureAuth(scopes: OAuthScope[], grantType: OAuthGrant) {
    const { domain } = this.getApiAndHost();
    const userPool = await this.initializeUserPool();
    const loginUrl = endpoints.cognito.callbackLoginUrl(domain);
    const logoutUrl = endpoints.cognito.callbackLogoutUrl(domain);

    // We don't want to add the custom redirect URL to the Cognito configuration
    // Since we don't really use Amplify on the client side, we can not read the custom state we set here anyway.
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: userPool.getClientId(),
          userPoolId: userPool.getUserPoolId(),
          loginWith: {
            oauth: {
              domain: this.domain,
              scopes: scopes,
              redirectSignIn: [loginUrl],
              redirectSignOut: [logoutUrl],
              responseType: grantType,
            },
            username: true,
          },
        },
      },
    });

    return userPool;
  }

  public async loginWithRedirect(url?: string) {
    await this.configureAuth(
      [OAuthScope.OPENID, OAuthScope.EMAIL, OAuthScope.PROFILE],
      OAuthGrant.TOKEN,
    );
    await signOut();

    // If we have a URL, store it for later retrieval to redirect to the user where they came from.
    setRedirectUrl(url ?? null, this.store.get(nowAtom));

    await signInWithRedirect({
      provider: { custom: "Yallo" },
    });
  }

  private async initializeUserPool(): Promise<CognitoUserPool> {
    if (this.initializedUserPool) {
      return this.initializedUserPool;
    }

    const { other_clients, userpool_id, remote_auth_endpoint } =
      await this.getKaminoSettings();

    this.domain = remote_auth_endpoint;
    let webClient = other_clients?.find(
      (value) => value.client_name === this.clientName,
    );

    if (!webClient && other_clients?.length) {
      webClient = other_clients[0];
    }

    const clientId = webClient?.client_id || "";

    this.initializedUserPool = new CognitoUserPool({
      UserPoolId: userpool_id.trim(),
      ClientId: clientId,
    });

    return this.initializedUserPool;
  }

  /**
   * Get the kamino settings from the kamino service
   */
  public async getKaminoSettings(): Promise<KaminoSettings> {
    const { publicApi, host } = this.getApiAndHost();
    try {
      const response = await publicApi.get<KaminoSettings>(
        endpoints.tenantSettings(host),
      );
      return response.data;
    } catch (error: unknown) {
      if (typeof error === "string") {
        throw new Error(error);
      }
      throw new Error();
    }
  }

  async getLogoutUrl(): Promise<string> {
    const { other_clients, remote_auth_endpoint } =
      await this.getKaminoSettings();
    this.domain = remote_auth_endpoint;

    let webClient = other_clients?.find(
      (value) => value.client_name === this.clientName,
    );

    if (!webClient && other_clients?.length) {
      webClient = other_clients[0];
    }

    const clientId = webClient?.client_id || "";

    const url = new URL(endpoints.cognito.logoutUrl(this.domain, clientId, ""));

    return url.href;
  }

  async logout() {
    await this.configureAuth(
      [OAuthScope.OPENID, OAuthScope.EMAIL, OAuthScope.PROFILE],
      OAuthGrant.TOKEN,
    );

    signOut();
    await this.getLogoutUrl();
  }

  private getApiAndHost(): {
    publicApi: AxiosInstance;
    host: string;
    domain: string;
  } {
    const { publicApi } = this.store.get(httpClientAtom);
    const { api: host, domain } = this.store.get(hostsAtom);

    if (!publicApi) throw new Error("No publicApi found");
    if (!host) throw new Error("No host found");
    if (!domain) throw new Error("No domain found");

    return { host, publicApi, domain };
  }
}
