import type { ReactElement } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useThumbnail } from "@sunrise/player";

import styles from "./thumbnail.module.css";

const Thumbnail = (): ReactElement | null => {
  const useLargeThumbnail = useMediaQuery("(min-width: 500px)");
  const { thumbnail } = useThumbnail({
    width: useLargeThumbnail ? 450 : 210,
  });

  if (!thumbnail) return null;

  return (
    <div
      className={styles.thumbnailContainer}
      style={{
        height: thumbnail.height,
        width: thumbnail.width,
      }}
    >
      <img
        className={styles.thumbnail}
        src={thumbnail.url}
        style={{
          height: thumbnail.fullHeight,
          width: thumbnail.fullWidth,
          top: -thumbnail.y,
          left: -thumbnail.x,
          transform: `scale(${thumbnail.resizeRatio}, ${thumbnail.resizeRatio})`,
          transformOrigin: `${thumbnail.x}px ${thumbnail.y}px`,
        }}
      />
    </div>
  );
};

export { Thumbnail };
