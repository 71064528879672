import { lazy, Suspense } from "react";
import { Route, Switch } from "wouter";

import { PageSpinner } from "@sunrise/yallo-web-components";

import { rawRoute } from "@/config/route";

import { ProtectedRoute } from "./protected-route";

const isProd = import.meta.env.MODE === "production";

// dynamic imports
const Tv = lazy(() => import("@/routes/tv"));
const Guide = lazy(() => import("@/routes/guide"));
const Home = lazy(() => import("@/routes/home"));
const Login = lazy(() => import("@/routes/login"));
const Pin = lazy(() => import("@/routes/pin"));
const Authenticate = lazy(() => import("@/routes/authenticate"));
const NotFound = lazy(() => import("@/routes/not-found"));
const Details = lazy(() => import("@/routes/details/details"));
const DevTools = lazy(() => import("@/routes/dev-tools/dev-tools"));
const DevToolsFeatureFlags = lazy(
  () => import("@/routes/dev-tools/feature-flags"),
);

const Recordings = lazy(() => import("@/routes/recordings/recordings"));
const RecordingDetailsEpisode = lazy(
  () => import("@/routes/recordings/recording-details-episode"),
);
const RecordingDetailsSeries = lazy(
  () => import("@/routes/recordings/recording-details-series"),
);
const RecordingDetailsSingle = lazy(
  () => import("@/routes/recordings/recording-details-single"),
);

export function Router(): JSX.Element {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Switch>
        <Route component={Home} path={rawRoute.home} />
        <Route component={Guide} path={rawRoute.guide} />
        <Route component={Recordings} path={rawRoute.recordings} />
        <Route
          component={RecordingDetailsEpisode}
          path={rawRoute.recordingEpisodeDetails}
        />
        <Route
          component={RecordingDetailsSeries}
          path={rawRoute.recordingSeriesDetails}
        />
        <Route
          component={RecordingDetailsSingle}
          path={rawRoute.recordingSingleDetails}
        />
        <Route component={Tv} path={rawRoute.tv} />
        <Route component={Details} path={rawRoute.details} />
        <Route component={Login} path={rawRoute.login} />
        {!isProd && (
          <>
            <Route component={DevTools} path={rawRoute.devTools} />
            <Route
              component={DevToolsFeatureFlags}
              path={rawRoute.devToolsFeatureFlags}
            />
          </>
        )}
        <Route component={Authenticate} path={rawRoute.authenticate} />
        <ProtectedRoute component={Pin} path={rawRoute.pin} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
