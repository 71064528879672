import { useCallback } from "react";
import { useAtomValue } from "jotai/react";

import { logoutFromKaminoMutationAtom, useAnonymousLogin } from "@sunrise/auth";
import { selectCanLogout } from "@sunrise/jwt";

import { logout as logoutFromCognito } from "@/core";

export function useLogout(logoutAllDevices = false) {
  const canUserLogout = useAtomValue(selectCanLogout);

  const { login: loginAnonymousUser } = useAnonymousLogin({
    autoLogin: false,
  });
  const { mutate: logoutFromKamino } = useAtomValue(
    logoutFromKaminoMutationAtom({ logoutAllDevices }),
  );

  const logout = useCallback(async () => {
    await logoutFromCognito();
    logoutFromKamino();
    await loginAnonymousUser();
  }, [loginAnonymousUser, logoutFromKamino]);

  return {
    canUserLogout,
    logout,
  };
}
