const LS_KEY = "redirect-on-login";
const TTL = 3600 * 1000;

export function getRedirectUrl(now: Date): string | null {
  const value = localStorage.getItem(LS_KEY);
  if (!value) return null;
  try {
    const { at, url } = JSON.parse(value);
    if (now.getTime() - at > TTL) return null;
    return url;
  } catch {
    return null;
  }
}

export function setRedirectUrl(url: string | null, now: Date) {
  if (!url) {
    localStorage.removeItem(LS_KEY);
    return;
  }
  localStorage.setItem(LS_KEY, JSON.stringify({ at: now.getTime(), url }));
}
