import type { NgSearchRecordingResult } from "@sunrise/search";
import { useContinueWatchingProgress } from "@sunrise/yallo-continue-watching";

import { route } from "@/config/route";
import { usePlayRecording } from "@/hooks/use-play-recording";

import { SearchEpisodeOrProgramResult } from "./search-episode-or-program-result";

export function SearchRecordingResult({
  item,
}: {
  item: NgSearchRecordingResult;
}) {
  const { play, canPlay, icon } = usePlayRecording({
    recordingId: item.recordingId,
  });

  const detailRoute = item.assetId
    ? route.recordings.single({
        assetId: item.assetId,
        recordingId: item.recordingId,
      })
    : undefined;

  const progress = useContinueWatchingProgress({
    recordingId: item.recordingId,
    start: new Date(item.epgStart),
    end: new Date(item.epgEnd),
    paddingStartMinutes: item.paddingStartMinutes,
    paddingEndMinutes: item.paddingEndMinutes,
  });

  return (
    <SearchEpisodeOrProgramResult
      detailRoute={detailRoute}
      item={item}
      play={canPlay ? play : undefined}
      playIcon={icon}
      progress={progress}
    />
  );
}
