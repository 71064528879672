import { useAtomValue } from "jotai";

import { selectCanLogin, selectCanUpgrade } from "@sunrise/jwt";
import { useTranslator } from "@sunrise/translator";
import { upsellLinkQueryAtom } from "@sunrise/yallo-upsell";
import { Button, Link } from "@sunrise/yallo-web-components";

import { login } from "@/core";

import styles from "./login-button.module.css";

export function LoginOrUpgradeButton() {
  const t = useTranslator();

  const { data: upgrade } = useAtomValue(upsellLinkQueryAtom("upgrade"));

  const canUpgradeToPremium = useAtomValue(selectCanUpgrade);
  const canUserLogin = useAtomValue(selectCanLogin);

  const handleLogin = () => {
    login();
  };

  if (canUserLogin) {
    return (
      <Button
        className={styles.authButton}
        data-testid="login-button"
        onClick={handleLogin}
      >
        {t("login_button")}
      </Button>
    );
  }

  if (upgrade && canUpgradeToPremium) {
    return (
      <Link
        className={styles.authButton}
        data-testid="upgrade-button"
        href={upgrade.upsell_link}
        variant="tonal"
      >
        {t("upgrade_button")}
      </Link>
    );
  }

  return null;
}
