import { CognitoService } from "@sunrise/auth";

import { webStore } from "./store";

const COGNITO_APP_CLIENT_NAME = import.meta.env.VITE_COGNITO_APP_CLIENT_NAME;

const createCognitoService = () => {
  if (!COGNITO_APP_CLIENT_NAME)
    throw new Error("Cognito has not been properly configured");
  return new CognitoService(webStore, COGNITO_APP_CLIENT_NAME);
};

export const login = (url?: string) => {
  const cognito = createCognitoService();
  return cognito.loginWithRedirect(url);
};

export const logout = () => {
  const cognito = createCognitoService();
  return cognito.logout();
};
