import { atomEffect } from "jotai-effect";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { getVideoElement } from "@sunrise/player";

export const isPlayingEffect = atomEffect((get) => {
  const isPlaying = get(areVideoAdsPlayingAtom);
  const element = getVideoElement();
  const key = "data-ads-playing";

  if (isPlaying) {
    element.setAttribute(key, "true");
  } else {
    element.removeAttribute(key);
  }

  return () => {
    element.removeAttribute(key);
  };
});
