import { atomEffect } from "jotai-effect";

import {
  areVideoAdsPlayingAtom,
  getAdCustomClickTrackingElement,
  isCustomClickTrackingElementUsedAtom,
} from "@sunrise/ads";

/**
 * When IMA reports that customClickTracking is used we need to "activate" this div
 * Adding the `data-custom-click-tracking` attribute will add the necessary CSS to
 * overlay the div on top of the player
 */
export const toggleCustomClickTrackingEffect = atomEffect((get) => {
  const isPlaying = get(areVideoAdsPlayingAtom);
  const isUsingCustomClickTracking = get(isCustomClickTrackingElementUsedAtom);
  const element = getAdCustomClickTrackingElement();

  element?.toggleAttribute(
    "data-custom-click-tracking",
    isUsingCustomClickTracking && isPlaying,
  );

  return () => {
    element?.removeAttribute("data-custom-click-tracking");
  };
});
